<template>
	<QuestionModal
		ref="pageDeleteModal"
		title="Seite löschen"
		question="Möchstest du die Seite wirklich löschen?"
		okText="Ja, löschen"
		cancelText="Nein, abbrechen"
		@ok="ok"
		@cancel="hide"
	/>
</template>

<script>
import QuestionModal from '../../../base/QuestionModal';
import { mapActions } from 'vuex';
export default {
	name: 'PageDeleteModal',
	components: {
		QuestionModal
	},
	props: {
		projectID: Number,
		surveyID: Number,
		pageID: Number
	},
	methods: {
		...mapActions(['deletePage']),
		show: function () {
			this.$refs.pageDeleteModal.show();
		},
		hide: function () {
			this.$refs.pageDeleteModal.hide();
		},
		ok: function () {
			this.hide();
			this.deletePage({
				projectID: this.projectID,
				surveyID: this.surveyID,
				pageID: this.pageID
			});

			this.$notify({
				group: 'main',
				type: 'success',
				title: 'Aktion erfolgreich',
				text: 'Die Seite wurde erfolgreich gelöscht!'
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
